.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.absolute {
    position: absolute;
}

@keyframes fade-in-and-out {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.fade {
    animation: fade-in-and-out infinite 10s ease-in;
    opacity: 0;
    width: 200px;
    height: 200px;
}
.fade-1 {
    animation-delay: 0s;
}
.fade-2 {
    animation-delay: 2.5s;
}
.fade-3 {
    animation-delay: 5s;
}
.fade-4 {
    animation-delay: 7.5s;
}

a {
    text-decoration: none;
}

/* custom react date time */
.rdt input {
    border: 2px solid transparent;
    padding: 5px 10px;
    background-color: #eee;
    outline: none;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
    width: 100%;
}

.rdt input:focus {
    border: 2px solid #14b1ab;
    outline-color: #14b1ab;
}
.rdt .rdtPicker {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}
